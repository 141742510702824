<template>
  <div class="home">
  </div>
</template>
<script>
export default {
  name: 'footer-bar',
  components: {
  }
}
</script>