<template>
  <div class="home mt-5">
    <div class="columns buttons are-large">
        <div class="column is-half"><router-link to="/scan"><button class="button is-success is-fullwidth">Scan Barcode</button></router-link></div>
        <div class="column is-half"><router-link to="/deliveries?app_view=1"><button class="button is-success is-fullwidth">Receive Delivery</button></router-link></div>
      </div>
      
    <div class="columns buttons are-large">
      <div class="column is-half"><router-link to="/deliveries"><button class="button is-info is-fullwidth">Orders</button></router-link></div> 
      
        <div class="column is-half"><router-link to="/jobs"><button class="button is-info is-fullwidth">Jobs</button></router-link></div>
      </div>
     <div class="columns buttons are-large">
      <div class="column is-half ">          <router-link to="/stones"><button class="button is-info is-fullwidth">Stones</button></router-link></div>
        <div class="column is-half"><router-link to="/reports"><button class="button is-info is-fullwidth">Reports</button></router-link></div>
      </div>
      <div class="columns buttons are-large">
        <div class="column is-half"><router-link to="/admin"><button class="button is-info is-fullwidth">Admin </button></router-link></div>
        <div class="column is-half" v-if="$auth.isAuthenticated"><button class="button is-danger is-fullwidth" @click="logout">Logout</button></div>
      </div>
      
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
    
  },
  methods: {
          logout() {
              if(confirm('Are you sure you want to logout ?'))
              {
                  this.$auth.logout({
                  returnTo: window.location.origin
                });
              }
      }
    }
}
</script>
