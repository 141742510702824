<template>
  <div id="app">
    <component :is="layout">

    </component>
  </div>
</template>



<script>
const default_layout = "fullwidth";

export default {
  name: 'Nav',
  computed: {
      layout() {
        return (this.$route.meta.layout || default_layout) + '-layout';
      }
    }
}
</script>