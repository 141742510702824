<script>
import NavBar from "@/components/NavBar";
import SideBar from "@/components/SideBar";
import FooterBar from "@/components/FooterBar";

export default {
  components: {
    NavBar,
    SideBar,
    FooterBar
  }
}
</script>

<template>
    <div>
    <nav-bar />

    <section class="main-content columns is-fullheight">
    <div class="column is-2">
    <side-bar />
    </div>


    <div class=" column is-10">
    <div class="section mt-5">
    <router-view/>


    </div>
    </div>
    </section>

    <footer-bar />

    </div>

</template>