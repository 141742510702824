import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import { authGuard } from "../auth/authGuard";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {layout: "fullwidth"},
    beforeEnter: authGuard
    
  },
  {
    path: '/stones',
    name: 'Stones',
    component: () => import(/* webpackChunkName: "stones" */ '../components/Stones.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/stones/add',
    name: 'AddStone',
    component: () => import(/* webpackChunkName: "admin" */ '../components/AddStone.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/stones/:id',
    name: 'ViewStone',
    component: () => import(/* webpackChunkName: "stones" */ '../components/ViewStone.vue'),
    beforeEnter: authGuard
  },
  
  {
    path: '/scan',
    name: 'Scan',
    meta: {layout: "fullwidth"},
    component: () => import(/* webpackChunkName: "stones" */ '../components/Scan.vue'),
    beforeEnter: authGuard
  },
  
  {
    path: '/deliveries',
    name: 'Deliveries',
    component: () => import(/* webpackChunkName: "stones" */ '../components/Deliveries.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/deliveries/add',
    name: 'AddDelivery',
    component: () => import(/* webpackChunkName: "stones" */ '../components/AddDelivery.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/deliveries/:id',
    name: 'ViewDelivery',
    component: () => import(/* webpackChunkName: "stones" */ '../components/ViewDelivery.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/receive_delivery/:id',
    name: 'ReceiveDelivery',
    component: () => import(/* webpackChunkName: "stones" */ '../components/ReceiveDelivery.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/jobs',
    name: 'Jobs',
    component: () => import(/* webpackChunkName: "stones" */ '../components/Jobs.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/jobs/add',
    name: 'AddJob',
    component: () => import(/* webpackChunkName: "admin" */ '../components/AddJob.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/jobs/:id',
    name: 'ViewJob',
    component: () => import(/* webpackChunkName: "admin" */ '../components/ViewJob.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/batches/add/:id',
    name: 'AddBatch',
    component: () => import(/* webpackChunkName: "admin" */ '../components/AddBatch.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/batches/:id',
    name: 'ViewBatch',
    component: () => import(/* webpackChunkName: "admin" */ '../components/ViewBatch.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/admin',
    name: 'Admin',
    meta: {layout: "fullwidth"},
    component: () => import(/* webpackChunkName: "admin" */ '../components/Admin.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/stone_types',
    name: 'StoneTypes',
    component: () => import(/* webpackChunkName: "admin" */ '../components/Admin/StoneTypes.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/stone_types/add',
    name: 'AddStoneType',
    component: () => import(/* webpackChunkName: "admin" */ '../components/Admin/AddStoneType.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/stone_types/:id',
    name: 'AddStoneType',
    component: () => import(/* webpackChunkName: "admin" */ '../components/Admin/ViewStoneType.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/stone_makes',
    name: 'StoneMakes',
    component: () => import(/* webpackChunkName: "admin" */ '../components/StoneMakes.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/stone_makes/add',
    name: 'AddStoneMake',
    component: () => import(/* webpackChunkName: "admin" */ '../components/AddStoneMake.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/colours',
    name: 'Colours',
    component: () => import(/* webpackChunkName: "admin" */ '../components/Colours.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/colours/add',
    name: 'AddColour',
    component: () => import(/* webpackChunkName: "admin" */ '../components/AddColour.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/suppliers',
    name: 'Suppliers',
    component: () => import(/* webpackChunkName: "admin" */ '../components/Suppliers.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/suppliers/add',
    name: 'AddSupplier',
    component: () => import(/* webpackChunkName: "admin" */ '../components/AddSupplier.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/reports',
    name: 'ReportsHome',
    component: () => import(/* webpackChunkName: "reports" */ '../components/Reports/Home.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/reports/batches-report',
    name: 'BatchesReport',
    component: () => import(/* webpackChunkName: "reports" */ '../components/Reports/BatchesReport.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/reports/on-hold-report',
    name: 'OnHoldReport',
    component: () => import(/* webpackChunkName: "reports" */ '../components/Reports/OnHoldReport.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/reports/stock-take-report',
    name: 'StockTakeReport',
    component: () => import(/* webpackChunkName: "reports" */ '../components/Reports/StockTakeReport.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/reports/stock-take-makes-report',
    name: 'StockTakeMakesReport',
    component: () => import(/* webpackChunkName: "reports" */ '../components/Reports/StockTakeMakesReport.vue'),
    beforeEnter: authGuard
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
