<template>
<nav class="navbar has-background-warning-dark  is-fixed-top" role="navigation" aria-label="main navigation">
  <div class="navbar-brand  ">
    <a href="/" class="navbar-item ">
    <img src="@/assets/logo.png" >
    </a>


  </div>

 
</nav>
</template>

<script>

  export default {
    name: 'NavBar',
    computed: {
        
      },
      methods: {
          logout() {
            if(confirm('Are you sure you want to logout ?'))
            {
            this.$auth.logout({
              returnTo: window.location.origin
            });
            }
      }
      }
  }
  </script>