<template>
   <div>
    <aside class="column  aside hero is-fullheight">
           Loading ...
         </aside>
   </div>
</template>

<script>
export default {
  name: 'side-bar',
  components: {
  }
}
</script>

